import React from 'react';
import './home.styles.scss';
import Logo from '../../assets/home_logo.webp';

const HomeRoute: React.FC = () => {
  return (
    <div className="home-route">
      <div className="banner">
        <div className="banner-container">
          <h1>The Corner Shop</h1>
          <img src={Logo} alt="The Corner Shop Logo"/>
          <div className="banner-shadow" />
        </div>
      </div>
    </div>
  );
};

export default HomeRoute;
