import React from 'react';
import { Routes, Route } from 'react-router-dom';

import DefaultLayout from './layouts/default/default.layout';
import AboutRoute from './routes/about/about.route';
import HomeRoute from './routes/home/home.route';
import StoryRoute from './routes/story/story.route';
import TeamRoute from './routes/team/team.route';
import WhatWeDoRoute from './routes/what-we-do/what-we-do.route';
import ContactRoute from './routes/contact/contact.route';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<DefaultLayout />}>
        <Route index element={<HomeRoute />} />
        <Route path='about' element={<AboutRoute />} />
        <Route path='story' element={<StoryRoute />} />
        <Route path='what-we-do' element={<WhatWeDoRoute />} />
        <Route path='team' element={<TeamRoute />} />
        <Route path='contact' element={<ContactRoute />} />
      </Route>
    </Routes>
  );
};

export default App;
