import React from 'react';
import './story.styles.scss';

const StoryRoute: React.FC = () => {

  return (
    <div className="story-route">
      <div className="container">
        <section>
          <h2>Our Story</h2>
          <p>The idea for theCornerShop was born out of a simple observation: small businesses often struggle with the
              complexities of managing their operations, especially as they scale. Traditional ERP systems are often too
              expensive, too complicated, or too rigid to meet the needs of growing e-commerce businesses. We knew there
              had to be a better way.</p>

          <p>Founded in 2024, theCornerShop started as a vision to create a platform that combines the power of AI with
              the flexibility that small businesses need. Our journey began with a team of dedicated professionals who
              shared a common goal: to build a solution that could level the playing field for small businesses. We spent
              countless hours listening to the needs of business owners, refining our platform, and ensuring that every
              feature we built was something that would genuinely make a difference.</p>

          <p>Today, theCornerShop is more than just an ERP system; it’s a partner to small businesses, helping them
              navigate the challenges of the e-commerce landscape and achieve their goals.</p>
        </section>
      </div>
    </div>
  );
};

export default StoryRoute;
