import React from 'react';
import './what-we-do.styles.scss';
import Infographic from '../../assets/wwd_infographic.svg';

const WhatWeDoRoute: React.FC = () => {
  return (
    <div className="what-we-do-route">
      <div className="container">
        <h2>What We Do</h2>
        <div className="description">
          <p>At theCornerShop, we offer a comprehensive AI-powered ERP system that simplifies every aspect of running an
            e-commerce business. From inventory management to customer engagement, our platform is designed to automate
            the mundane and empower business owners to focus on growth.</p>

          <p>Inventory Management: Our system seamlessly tracks inventory across multiple channels, ensuring that
            businesses always know what they have in stock, where it is, and when it’s time to reorder. With advanced
            AI-driven analytics, we help businesses make informed decisions about purchasing and inventory levels.</p>

          <p>Customer Engagement: We understand that customer relationships are key to success. That’s why our platform
            includes tools for personalized marketing, loyalty programs, and customer support, all driven by AI to
            ensure that businesses can connect with their customers in meaningful ways.</p>

          <p>Sales and Reporting: TheCornerShop provides real-time sales data and comprehensive reporting tools that give
            businesses a clear picture of their performance. Our system is designed to handle complex sales data,
            offering insights that help businesses grow and thrive.</p>

          <p>Automation: One of the most powerful aspects of theCornerShop is our commitment to automation. We know that
            small business owners wear many hats, so we’ve built a system that automates routine tasks, from processing
            orders to generating financial reports, freeing up time for what really matters.</p>

          <p>With theCornerShop, small businesses gain access to the tools they need to compete in a rapidly evolving
            market. We’re here to support their journey, every step of the way.</p>
        </div>

        <img src={Infographic} alt="How It Works" />
      </div>
    </div>
  );
};

export default WhatWeDoRoute;
