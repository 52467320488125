import React from 'react';
import './about.styles.scss';

const AboutRoute: React.FC = () => {
  return (
    <div className="about-route">
      <div className="container">
        <section>
          <h2>About Us</h2>
          <p>At theCornerShop, we’re on a mission to empower small businesses by providing them with an advanced, AI-powered ERP solution
              tailored specifically for the e-commerce industry. We understand the unique challenges that small businesses face,
              from managing inventory to engaging customers, and we’ve designed our platform to simplify these processes,
              allowing business owners to focus on what they do best: growing their business.</p>

          <p>Our team is a blend of experienced engineers, product managers, and AI specialists who are passionate about making
              cutting-edge technology accessible to everyone. We believe that technology should be an enabler, not a barrier,
              and that’s why we’ve created a solution that’s not only powerful but also easy to use.</p>
        </section>
      </div>
    </div>
  );
};

export default AboutRoute;
