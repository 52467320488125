import React from 'react';
import './contact.styles.scss';

interface FormData {
    name: string;
    email: string;
    phone: string;
    message: string;
}

const ContactRoute: React.FC = () => {
  const [formData, setFormData] = React.useState<FormData>({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Send form data to the serverless endpoint (your Lambda function)
    try {
      const response = await fetch('https://api.thecornershop.ai/send-email', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Your message has been sent!');
        setFormData({name: '', email: '', phone: '', message: ''}); // Reset form after successful submission
      } else {
        alert('There was an error sending your message.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('There was an error sending your message.');
    }
  };


  return (
    <div className="contact-route">
      <div className="container">
        <section>
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
              <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
              />
              <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
              />
              <input
                  type="tel"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
              />
              <textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
              />
              <button type="submit">Send</button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ContactRoute;
