import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import FooterComponent from '../../components/footer/footer.component';
import NavigationComponent from '../../components/navigation/navigation.component';

const DefaultLayout: React.FC = () => {
  const [navFgLight, setNavFgLight] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    let bg: string;
    switch (location.pathname) {
      case '/':
      case '/team':
        bg = 'none';
        setNavFgLight(false);
        break;
      case '/about':
      case '/story':
      case '/what-we-do':
      case '/contact':
        bg = '#daccbc';
        setNavFgLight(false);
        break;
      default:
        bg = 'none';
        setNavFgLight(false);
    }
    window.document.body.style.background = bg;
  }, [location]);

  return (
    <div>
      <NavigationComponent navFgLight={navFgLight} />
      <div className="default-layout">
        <Outlet />
      </div>
      <FooterComponent />
    </div>
  );
};

export default DefaultLayout;
