import React from 'react';

import './footer.styles.scss';

const FooterComponent: React.FC = () => {
  return (
    <div className="footer-component">
      <span>&copy; TheCornerShop 2024</span>
    </div>
  );
};

export default FooterComponent;
