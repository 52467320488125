import React from 'react';

import './team.styles.scss';

import personPR from '../../assets/person_pr.png';
import personHH from '../../assets/person_hh.png';
import personJM from '../../assets/person_jm.png';
import personEW from '../../assets/person_ew.png';
// import personCAT from '../../assets/person_cat.png';
// import FooterComponent from '../../components/footer/footer.component';

/* private */ interface Person {
  image: string;
  name: string;
  title: string;
  about: string;
}

/* private */ const people: Person[] = [
  {
    image: personPR,
    name: 'Partha Roarke',
    title: 'Founder/CEO',
    about: 'A visionary leader with a passion for empowering small businesses with AI-driven solutions.',
  },
  {
    image: personHH,
    name: 'Haritha Haridas',
    title: 'Product/Program Manager',
    about: 'Expert in product development and program management, driving innovative solutions.',
  },
  {
    image: personJM,
    name: 'James Murray',
    title: 'Principal Software Architect',
    about: 'Architect of scalable and robust software solutions, bringing AI to the forefront.',
  },
  {
    image: personEW,
    name: 'Eric Wagner',
    title: 'Lead Engineer',
    about: 'Technical lead and coding enthusiast, mobile and full stack, ensuring seamless integration and functionality.',
  },
  //{
  //  image: personCAT,
  //  name: 'Rasam',
  //  title: 'Chief Morale Officer',
  //  about: 'Rasam brings a wealth of experience to her role as Chief Morale Office at The Corner Shop.  With a proven track record in playing, hunting, purring, and napping, Rasam is an expert in keeping the team motivated and stree-free.',
  //},
]

/* private */ const PersonComponent: React.FC<{person: Person}> = (data: {person: Person}) => {
  return (
    <div className="person-component">
      <img className="image" src={data.person.image} alt="Team Member Portrait" />
      <div className="content">
        <h3 className="name">{data.person.name}</h3>
        <h4 className="title">{data.person.title}</h4>
        <p className="about">{data.person.about}</p>
      </div>
    </div>
  );
};

const TeamRoute: React.FC = () => {
  return (
    <div className="team-route">
      <h2>Our Team</h2>
      <div className="container">
        {people.map(person => <PersonComponent person={person} />)}
      </div>
    </div>
  );
};

export default TeamRoute;
